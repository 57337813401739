import { MyProfileFragment, type NavbarConfigFragment } from "@gql-schema";
import { NavBarBurgerMenu, NavBarBurgerMenuProps } from "@ui/components/NavBar/NavBarBurgerMenu";
import { memo } from "react";
import { MobileMenu } from "./MobileMenu";

interface BaseProps extends Omit<NavBarBurgerMenuProps, "menuId"> {
    activeMenuItemIndex?: number;
    profile: MyProfileFragment | null;
    showFullCustomerZone?: boolean;
    userName?: string;
}

type Props = BaseProps &
    (
        | { navbarData: NavbarConfigFragment; hideLandingPageMenu?: false }
        | { navbarData?: never; hideLandingPageMenu: true }
    );

export const BurgerMenu = memo<Props>((props) => {
    const { navbarData, activeMenuItemIndex, hideLandingPageMenu, profile, showFullCustomerZone, ...restProps } = props;

    return (
        <NavBarBurgerMenu menuId="burger-menu" {...restProps}>
            <MobileMenu
                activeMenuItemIndex={activeMenuItemIndex}
                className={!hideLandingPageMenu ? "pt-4" : undefined}
                hideLandingPageMenu={hideLandingPageMenu}
                navbarData={navbarData}
                profile={profile}
                showFullCustomerZone={showFullCustomerZone}
            />
        </NavBarBurgerMenu>
    );
});

BurgerMenu.displayName = "BurgerMenu";
