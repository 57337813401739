import { CUSTOMER_ZONE_MENU_ITEM, USER_MENU } from "@config/configureMenu";
import { MyProfileFragment } from "@gql-schema";
import { useOnLogin } from "@shared/components/Navigation/elements/useOnLogin";
import { NavBarLoginButton } from "@ui/components/NavBar/NavBarLoginButton";
import { NavBarPopoverContainer } from "@ui/components/NavBar/styles/NavBarPopoverContainer";
import { useCloseToggleLayerOnEscKey } from "@ui/hooks/useCloseToggleLayerOnEscKey";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { getResizeObserver } from "@ui/utils/getResizeObserver";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { useMinWindowWidth } from "@uxf/core/hooks/useMinWindowWidth";
import { composeRefs } from "@uxf/core/utils/composeRefs";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useLayer } from "react-laag";
import { LoggedUserMenu } from "./LoggedUserMenu";

const MENU_ID = "desktop-user-menu";
const LOGIN_ITEM = USER_MENU.login;

interface Props {
    profile: MyProfileFragment | null;
    variant?: "white" | "green" | "default";
}

export const DesktopUserMenu = memo<Props>((props) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLAnchorElement>(null);

    const { profile, variant, ...restProps } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const close = useCallback(() => setIsOpen(false), []);
    const toggle = useCallback(() => setIsOpen((prev) => !prev), []);

    const isMounted = useIsMounted();
    const isDesktop = useMinWindowWidth(BREAKPOINTS.xl);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        auto: true,
        containerOffset: 16,
        isOpen: isOpen,
        onDisappear: (type) => type === "full" && close(),
        onOutsideClick: close,
        overflowContainer: false,
        possiblePlacements: ["bottom-end"],
        preferX: "left",
        snap: true,
        triggerOffset: 8,
        ResizeObserver: getResizeObserver(),
    });

    useCloseToggleLayerOnEscKey(isOpen, close, dropdownRef, triggerRef);

    useEffect(() => {
        const node = dropdownRef.current;
        if (node) {
            node.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isDesktop && isOpen) {
            close();
        }
    }, [close, isDesktop, isOpen]);

    const onLogin = useOnLogin(profile);

    const title = <span className="block uppercase">{CUSTOMER_ZONE_MENU_ITEM.title}</span>;

    if (!profile?.email) {
        return (
            <NavBarLoginButton
                $height={36}
                $ml={24}
                analyticsCallback={LOGIN_ITEM.analyticsCallback}
                menuKey={LOGIN_ITEM.menuKey}
                route={LOGIN_ITEM.route}
                variant={variant}
                title={title}
            />
        );
    }

    const titleWithName = (
        <>
            {title}
            <span className="block text-10 leading-normal opacity-60">
                přihlášen {profile.surname ? `${profile.name} ${profile.surname}` : profile.surname}
            </span>
        </>
    );

    if (!profile.isLogged) {
        return (
            <NavBarLoginButton
                $height={profile.name ? 44 : 36}
                $ml={24}
                menuKey={LOGIN_ITEM.menuKey}
                onClick={onLogin}
                variant={variant}
                title={profile.name ? titleWithName : title}
            />
        );
    }

    return (
        <>
            <NavBarLoginButton
                aria-controls={MENU_ID}
                aria-haspopup
                aria-expanded={isOpen}
                $height={44}
                $ml={24}
                onClick={toggle}
                ref={composeRefs(triggerRef, triggerProps.ref)}
                title={titleWithName}
                variant={variant}
                {...restProps}
            />
            {isMounted &&
                isOpen &&
                renderLayer(
                    <NavBarPopoverContainer
                        id={MENU_ID}
                        ref={composeRefs(dropdownRef, layerProps.ref)}
                        style={layerProps.style}
                    >
                        <LoggedUserMenu />
                    </NavBarPopoverContainer>,
                )}
        </>
    );
});

DesktopUserMenu.displayName = "DesktopUserMenu";
