import { CUSTOMER_ZONE_MENU, CUSTOMER_ZONE_MENU_ITEM, USER_MENU } from "@config/configureMenu";
import { useGetActiveMenuItem } from "@shared/hooks/ui/useGetActiveMenuItem";
import { limitActiveMenuItemDepth } from "@shared/utils/limitActiveMenuItemDepth";
import { Box } from "@ui/components/Box/Box";
import { Divider } from "@ui/components/Divider/Divider";
import { Icon } from "@ui/components/Icon/Icon";
import { NavBarMobileMenuItem } from "@ui/components/NavBar/NavBarMobileMenuItem";
import { COLORS } from "@ui/theme/colors";
import { cx } from "@uxf/core/utils/cx";
import { memo } from "react";

interface Props {
    className?: string;
    showFullCustomerZone?: boolean;
    userName?: string;
}

export const LoggedUserMenu = memo<Props>((props) => {
    const { className, showFullCustomerZone, userName } = props;

    const activeMenuItem = useGetActiveMenuItem();

    return (
        <Box className={cx(userName && "bg-green-secondary", className)}>
            {!!userName && (
                <div className="flex h-15 items-center bg-green-secondaryDark px-6 text-green">
                    <div className="mr-2 shrink-0">
                        <Icon name="user" size={24} $width={32} />
                    </div>
                    <div className="text-16 font-bold leading-tight">{userName}</div>
                </div>
            )}
            <div className="py-2">
                {showFullCustomerZone ? (
                    <>
                        {CUSTOMER_ZONE_MENU.map(({ menuKey, iconName, ...restItem }) => (
                            <NavBarMobileMenuItem
                                $color={COLORS.green.default}
                                $textTransform="none"
                                isActive={menuKey === limitActiveMenuItemDepth(activeMenuItem, 2)}
                                key={menuKey}
                                startIcon={iconName ? <Icon name={iconName} size={24} $width={32} /> : undefined}
                                {...restItem}
                            />
                        ))}
                        <div className="my-2 px-6">
                            <Divider $lineColor={COLORS.green.lighter} />
                        </div>
                        {[USER_MENU.userSettings, USER_MENU.logout].map(({ menuKey, iconName, ...restItem }) => (
                            <NavBarMobileMenuItem
                                $color={COLORS.green.default}
                                $textTransform="none"
                                isActive={menuKey === limitActiveMenuItemDepth(activeMenuItem, 2)}
                                key={menuKey}
                                startIcon={iconName ? <Icon name={iconName} size={24} $width={32} /> : undefined}
                                {...restItem}
                            />
                        ))}
                    </>
                ) : (
                    [CUSTOMER_ZONE_MENU_ITEM, USER_MENU.userSettings, USER_MENU.logout].map(
                        ({ menuKey, iconName, ...restItem }) => (
                            <NavBarMobileMenuItem
                                $color={COLORS.green.default}
                                $textTransform="none"
                                isActive={menuKey === limitActiveMenuItemDepth(activeMenuItem, 2)}
                                key={menuKey}
                                startIcon={iconName ? <Icon name={iconName} size={24} $width={32} /> : undefined}
                                {...restItem}
                            />
                        ),
                    )
                )}
            </div>
        </Box>
    );
});

LoggedUserMenu.displayName = "LoggedUserMenu";
