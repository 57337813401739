import { MyProfileFragment } from "@gql-schema";
import { useProfileMeQuery } from "@gql-schema/ProfileQueries";
import * as Sentry from "@sentry/nextjs";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { useSaveMarketingProfile } from "@shared/utils/marketingProfile/useSaveMarketingProfile";
import { isBrowser } from "@uxf/core/utils/isBrowser";
import { useEffect } from "react";

export const useLoggedProfile = (): MyProfileFragment | null => {
    const { data } = useProfileMeQuery();
    const saveMarketingProfile = useSaveMarketingProfile();

    useEffect(() => {
        if (isBrowser && data?.profileMe) {
            const userRole = data.profileMe.backofficeProfile
                ? data.profileMe.backofficeProfile.id === data.profileMe.id
                    ? "BackOffice"
                    : "Signed_User_BO"
                : data.profileMe.isLogged
                  ? "Signed_User"
                  : "User";

            if (AnalyticsService.getUserRole() !== userRole) {
                AnalyticsService.setUserRole(userRole);
                saveMarketingProfile({ eventName: "Uzivatelska Role", eventValueText1: userRole });
            }
            AnalyticsService.setMarketingProfile(data.profileMe.activeCampaigns);
        }
        AnalyticsService.setAccountUuid(data?.profileMe?.uuid ?? "");
    }, [data?.profileMe, saveMarketingProfile]);

    const profileUuid: string = data?.profileMe?.uuid ?? "";
    const email: string = data?.profileMe?.email ?? "";

    useEffect(() => {
        if (profileUuid) {
            Sentry.setUser({
                ...(data?.profileMe ?? {}),
                id: profileUuid,
                email: email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileUuid, email]);

    return data?.profileMe || null;
};
